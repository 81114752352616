@import '../../styles/partials/index';

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: $box-padding;
    padding-right: $box-padding;

    @include breakpoint(mobile, max){
        padding-left: 0;
        padding-right: 0;
      }

    .dynImage {
        max-width: 48%;
        margin-bottom: 1em;

        @include breakpoint(mobile, max){
          max-width: 500px;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
    }
}

.singleImage {
  width: 100%;
  margin-top: 2em;

  figure {
    img {
      width: 100%
    }
  }
}