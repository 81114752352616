
@import '../../styles/partials/index';

.dynImage, .singleImage {
    width: 100%;
    box-sizing: border-box;

    a {
        text-decoration: none;
    }

    figure {
        margin: 0px;
        background-color: $background-secondary;
        border: 1px solid $background-secondary;

        img {
            max-width: 100%;;
        }
    
        figcaption {
            color: $font-color-ondark;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
            padding-left: 1em;
            padding-right: 1em;
            font-size: 0.8rem;;
        }
    }
}