// A map of breakpoints.
$breakpoints: (
  mobile: 768px,
  laptop: 1240px,
  desktop-lg: 1800px
);

@mixin breakpoint($breakpoint, $direction) {
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    
    @if $direction == max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    } @else if $direction == min {      
      @media (min-width: $breakpoint-value) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint-value) {
        @content
      }
    }  
  } @else {
    @if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else if $direction == min {      
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint) {
        @content
      }
    }
  }
}
/*
// Extra small devices (phones, 600px and down) 
@media only screen and (max-width: 600px) {

}

// Small devices (portrait tablets and large phones, 600px and up) 
@media only screen and (min-width: 600px) {}

// Medium devices (landscape tablets, 768px and up) 
@media only screen and (min-width: 768px) {}

//Large devices (laptops/desktops, 992px and up) 
@media only screen and (min-width: 992px) {}

// Extra large devices (large laptops and desktops, 1200px and up) 
@media only screen and (min-width: 1200px) {}
   
}
*/