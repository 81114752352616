$box-padding: 40px;


// Colors
$font-color: rgb(102, 102, 102);
$font-color-secondary: #666;
$font-color-thernary: #ff4411;
$font-color-ondark: #d2cfcf;

$background: #fff;
$background-secondary: #353535;


// SIZES
$header-laptop: 190px;
$header-mobile: 120px;

$content-width: 980px;