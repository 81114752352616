@import '../../styles/partials/index';

.logo {
    position: absolute;
    top: 0;
    left: 0;
    height: $header-laptop;
    min-width: 100%;
    background-color: #353535;

    @include breakpoint(mobile, max){
        height: $header-mobile;
    }

    figure {
        height: $header-laptop;
        width: 500px;
        margin: 0;
        padding: 0;

        @include breakpoint(mobile, max){
            height: $header-mobile;
            width: 270px;
        }

        a {
            height: 100%;
            display: block;

            img {
                height:100%;
                width:100%;
                object-fit: contain;
                position: relative;
                top: 12px;
            }
        }
    }
}