@import '../../styles/partials/index';

.partner-board {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    padding-top: 1em;

    @include breakpoint(mobile, max){
        width: 100%; 
        text-align: center;
    }

    .partnerWrap {
        margin: 1em;

        @include breakpoint(mobile, max){
            width: 80%; 
            margin-bottom: 1em;
        }

        figure {
            width: 200px;
            min-height: 160px;
            margin: 0;

            @include breakpoint(mobile, max){
                width: 80%; 
                text-align: center;
                margin: auto;
                min-height: inherit;
            }
    
            a {
                min-height: 160px;
                text-decoration: none;
                height:100%;
                width: 100%;
                display: block;

                @include breakpoint(mobile, max){
                    width: 80%; 
                    text-align: center;
                    margin: auto;
                    min-height: inherit;
                }
            }
    
            img {
                max-width: 100%;
            }
    
            .no-logo {
                display: block;
                font-size: 2em;
            }
        }
    }
}