.flex-image {
    height:500px;
    width: 100%;
    figure {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        background-repeat: no-repeat;
        background-size: cover; 
        background-position: center;

        // 54%
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        figure {
            background-position: 54%;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {}

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {}

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {}

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {}
}
