@import '../styles/partials/variables';

h2 { color: $font-color-thernary; font-size: 32px; font-family: 'Signika', sans-serif; padding-bottom: 10px; }


p { font-family: 'Inder', sans-serif; line-height: 28px; margin-bottom: 15px; color: #666; }


a { color: $font-color-thernary; transition: .5s; -moz-transition: .5s; -webkit-transition: .5s; -o-transition: .5s; }


a:hover { color: #a03c21 }


.date { float: left }


.author { float: right }


.entry-content { border-bottom: 3px solid #666; padding: 0 0 15px 0; margin-bottom: 8px; }


.meta-info { padding: 5px; overflow: hidden; }


.meta-info p { font-family: 'Fenix', serif; font-style: italic; color: #666; font-size: 16px; }