@import '../../styles/partials/index';

  .footer {
    .contact-info {
      background-color: #353535;
      border-top: 1px solid #000;
      padding: 1em;
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;

      .contact-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        align-items: center;
  
        li {
          order: 0;
          flex: 0 1 auto;
          align-self: auto;
          margin: 0.5em;
          color: #d2cfcf;

          @include breakpoint(mobile, max){
            display:block
          }

          svg {
            margin-right: 0.5em;
            position: relative;
            top: 3px;
          }

          a {
            color: #d2cfcf;
          }
        }
    }
  }
}

  