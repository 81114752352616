@import "../../styles/partials/index";

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  //background: #373a47;
  background: #d2cfcf;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #d2cfcf;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: block;
}

/* Individual item */
.bm-item {
  display: block;

  color: #d2cfcf;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Horizontal menu */
.horizontal-menu {
  letter-spacing: 1px;
  line-height: 2em;
  position: relative;
  top: 155px;
  width: 100%;
  padding-left: 250px;
  // display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  font-weight: 600;

  .hm-item {
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none;
  }

  @include breakpoint(mobile, max) {
    display: none;
    top: 70px;
  }
}
