@import './Text.scss';
@import './partials/index';


.App {
  text-align: left;
}

.App-header {
  background-color: $background;
  border-bottom: 2px solid $font-color-thernary;
  height: $header-laptop + 2px;
  box-sizing: border-box;

  @include breakpoint(mobile, max){
    height: $header-mobile + 2px;
  }
}

main {
  padding-top: 1em;

  @include breakpoint(mobile, max){
    margin-top: 100px;
    padding-top: 1em;
  }

  @include breakpoint(laptop, max){
    margin-top: 0px;
  }

  .page-content {
    margin: auto;
    max-width: $content-width;
    margin-bottom: 1em;

    @include breakpoint(laptop, max){
      padding-left: 1em;
      padding-right: 1em;
    }

      h2 {
        border-bottom: 1px solid #aaa;
        margin: 15px 0;
        padding: 5px 0;
        position: relative;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: lighter;

        .headingSub {
          font-size: 0.5em;
          display: block;
        }
      }
    
      @include breakpoint(mobile, min){
        h2:before,
        h2:after {
          content: '';
          border-right: 20px solid #fff;
          border-top: 15px solid #aaa;
          bottom: -15px;
          position: absolute;
          left: 25px;
        }
      
        h2:after {
          border-top-color: #fff;
          border-right-color: transparent;
          bottom: -13px;
          left: 26px;
        }
      }

      ul, ol, p, table {
        padding-left: $box-padding;
        padding-right: $box-padding;

        @include breakpoint(mobile, max){
          padding-left: 0;
          padding-right: 0;
        }
      }

      .contactlist {
        li {
          list-style-type: none;
          padding: 0.2em;


          a {
            color: inherit;
          }
        }
      }
  }

  .scaledImage {
    box-sizing: border-box;
    position: relative;
    min-width: 100%;
    top: 2em;

    img {
      position: relative;
      top: 3px;
      min-width: inherit;
      box-sizing: border-box;
    }
  }
}

.hidden {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute!important;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  clip: rect(1px,1px,1px,1px);
}

