@import '../../styles/partials/variables';

ul.checklist {
    margin: 0;
    margin-left: $box-padding;

    li {
        list-style-type: none;
        list-style-image: url('./check-30-30.png');
        line-height: 30px;

        .checklistText {
            display: block;
            line-height: 30px;
            top: -10px;
            position: relative;
        }
    }
}