@import '../../styles/partials/index';

.slogan {
    font-size: 150%;
    text-align: center;
    margin-top: 2em;
    padding: 1em;
    line-height: 120%;

    @include breakpoint(mobile, max){
      line-height: 132%;
      text-align: left;
    }
}

.freeAsInfreeBeer {
  font-size: 1.5em;  
  display: block;
}

.sub {
  display: block;
  font-size: 0.8em;;
}